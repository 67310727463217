@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a,
  .a {
    @apply text-forest no-underline;
  }

  h1,
  .h1 {
    @apply font-sourceSerif4;
  }

  li {
    margin-left: 1em;
  }
}

@layer utilities {
  .bg-urology-gradient {
    background: linear-gradient(
      95deg,
      hsl(var(--twc-primary-800)) -7.85%,
      hsl(var(--twc-primary-600)) 47.75%,
      hsl(var(--twc-primary-300)) 114.85%
    );
  }

  .bg-urology-gradient-reversed {
    background: linear-gradient(
      93.75deg,
      hsl(var(--twc-primary-300)) 0%,
      hsl(var(--twc-primary-600)) 35%,
      hsl(var(--twc-primary-800)) 100%
    );
  }

  .progress-bar-gradient {
    background: linear-gradient(
      95deg,
      hsl(var(--twc-primary-800)) -7.85%,
      hsl(var(--twc-primary-600)) 47.75%,
      hsl(var(--twc-primary-300)) 114.85%
    );
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  .generalBlockSpacing {
    @apply md:my-7 my-5 px-5 pb-8;
  }
}
